@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css');
html, body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: 'Pretendard Variable', -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  z-index: -10;
  -webkit-text-size-adjust: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0 1px rgba(0,0,0,0.2);
  background-color: #ffffff;
}

body {
  overflow-y: scroll;
}

input, textarea, button {
  font-family: 'Noto Sans KR', 'Apple SD Gothic Neo', 'Nanum Gothic', sans-serif;
}

div, button, input {
  box-sizing: border-box;
}

img {
  width: 100%;
}